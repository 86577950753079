// extracted by mini-css-extract-plugin
export const index = "index-module--index--_WAhF";
export const background = "index-module--background--gt8fy";
export const bg1 = "index-module--bg1--2Uhlf";
export const animateBG1 = "index-module--animateBG1--1b3Kt";
export const bg2 = "index-module--bg2--1cnOY";
export const animateBG2 = "index-module--animateBG2--PACPF";
export const bg3 = "index-module--bg3--1svqd";
export const animateBG3 = "index-module--animateBG3--3OGps";
export const bg4 = "index-module--bg4--17SAD";
export const animateBG4 = "index-module--animateBG4--2MS4G";
export const bg5 = "index-module--bg5--3zmag";
export const animateBG5 = "index-module--animateBG5--3POxC";
export const bg6 = "index-module--bg6--2RvRA";
export const animateBG6 = "index-module--animateBG6--1u8hI";
export const bg7 = "index-module--bg7--RzxA0";
export const animateBG7 = "index-module--animateBG7--m2znw";
export const letterSpacing = "index-module--letter-spacing--1-Hk-";
export const letterSpacing2 = "index-module--letter-spacing2--Ll4oE";
export const caps = "index-module--caps--2j28b";
export const logoLine2 = "index-module--logo-line2--2Dl0Q";
export const navMain = "index-module--nav-main--2Qr5q";